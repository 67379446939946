// parse slide data (url, title, size ...) from DOM elements
// (children of gallerySelector)
const parseThumbnailElements = (el) => {
  const thumbElements = el.childNodes;
  const numNodes = thumbElements.length;
  const items = [];

  let linkEl;
  let size;
  let item;

  for (let i = 0; i < numNodes; i += 1) {
    const figureEl = thumbElements[i]; // <figure> element

    // include only element nodes
    if (figureEl.nodeType !== 1) {
      continue; // eslint-disable-line no-continue
    }

    // eslint-disable-next-line prefer-destructuring
    linkEl = figureEl.children[0]; // <a> element
    if (linkEl.tagName !== 'A') {
      continue; // eslint-disable-line no-continue
    }
    size = linkEl.getAttribute('data-size').split('x');

    // create slide object
    item = {
      src: linkEl.getAttribute('href'),
      w: parseInt(size[0], 10),
      h: parseInt(size[1], 10),
    };

    if (figureEl.children.length > 1) {
      // <figcaption> content
      item.title = figureEl.children[1].innerHTML;
    }

    if (linkEl.children.length > 0) {
      // <img> thumbnail element, retrieving thumbnail url
      item.msrc = linkEl.children[0].getAttribute('src');
    }

    item.el = figureEl; // save link to element for getThumbBoundsFn
    items.push(item);
  }

  return items;
};

// find nearest parent element
const closest = (el, fn) => el && (fn(el) ? el : closest(el.parentNode, fn));

const openPhotoSwipe = (index, galleryElement, disableAnimation, fromURL) => {
  const pswpElement = document.querySelectorAll('.pswp')[0];
  const items = parseThumbnailElements(galleryElement);

  // define options (if needed)
  const options = {
    // define gallery index (for URL)
    galleryUID: galleryElement.getAttribute('data-pswp-uid'),
    getThumbBoundsFn(i) {
      // See Options -> getThumbBoundsFn section of documentation for more info
      const thumbnail = items[i].el.getElementsByTagName('img')[0];
      // find thumbnail
      const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
      const rect = thumbnail.getBoundingClientRect();

      return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
    },
  };

  // PhotoSwipe opened from URL
  if (fromURL) {
    if (options.galleryPIDs) {
      // parse real index when custom PIDs are used
      // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
      for (let j = 0; j < items.length; j += 1) {
        if (items[j].pid === index) {
          options.index = j;
          break;
        }
      }
    } else {
      // in URL indexes start from 1
      options.index = parseInt(index, 10) - 1;
    }
  } else {
    options.index = parseInt(index, 10);
  }

  // exit if index not found
  if (Number.isNaN(options.index)) {
    return;
  }

  if (disableAnimation) {
    options.showAnimationDuration = 0;
  }

  // Pass data to PhotoSwipe and initialize it
  const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
  gallery.init();
};

// triggers when user clicks on thumbnail
const onThumbnailsClick = (e) => {
  e.preventDefault();

  const eTarget = e.target || e.srcElement;

  // find root element of slide
  const clickedListItem = closest(eTarget, (el) => el.tagName && el.tagName.toUpperCase() === 'FIGURE');

  if (!clickedListItem) {
    return;
  }

  // find index of clicked item by looping through all child nodes
  // alternatively, you may define index via data- attribute
  const clickedGallery = clickedListItem.parentNode;
  const { childNodes } = clickedGallery;
  const numChildNodes = childNodes.length;

  let nodeIndex = 0;
  let index;

  for (let i = 0; i < numChildNodes; i += 1) {
    if (childNodes[i].nodeType !== 1) {
      continue; // eslint-disable-line no-continue
    }

    if (childNodes[i] === clickedListItem) {
      index = nodeIndex;
      break;
    }

    nodeIndex += 1;
  }

  if (index >= 0) {
    // open PhotoSwipe if valid index found
    openPhotoSwipe(index, clickedGallery);
  }
};

// parse picture index and gallery index from URL (#&pid=1&gid=2)
const photoswipeParseHash = () => {
  const hash = window.location.hash.substring(1);
  const params = {};

  if (hash.length < 5) {
    return params;
  }

  const vars = hash.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    if (!vars[i]) {
      continue; // eslint-disable-line no-continue
    }

    const pair = vars[i].split('=');
    if (pair.length < 2) {
      continue; // eslint-disable-line no-continue
    }

    // eslint-disable-next-line prefer-destructuring
    params[pair[0]] = pair[1];
  }

  if (params.gid) {
    params.gid = parseInt(params.gid, 10);
  }

  return params;
};

const initPhotoSwipeFromDOM = (gallerySelector) => {
  const galleryElements = document.querySelectorAll(gallerySelector);

  for (let i = 0, l = galleryElements.length; i < l; i += 1) {
    galleryElements[i].setAttribute('data-pswp-uid', i + 1);

    if (galleryElements[i].querySelector('a')) {
      galleryElements[i].onclick = onThumbnailsClick;
    }
  }

  // Parse URL and open gallery if it contains #&pid=3&gid=1
  const hashData = photoswipeParseHash();

  if (hashData.pid && hashData.gid) {
    openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
  }
};

export const initPhotoSwipe = () => {
  initPhotoSwipeFromDOM('[data-photoswipe-init]');
};
