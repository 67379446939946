import { popitup } from './popup';

export const initPrintPages = () => {
  const $printPages = document.querySelectorAll('[data-print-popup]');

  for (let i = 0, len = $printPages.length; i < len; i += 1) {
    $printPages[i].addEventListener('click', (e) => {
      popitup($printPages[i].dataset.printPopup);
      e.preventDefault();
    });
  }
};
