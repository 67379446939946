import serializeForm from 'form-serialize';

import { initPrintPages } from './print';
import { popitup } from './popup';
import { initShareToNetworks } from './networks';
import { initShareToClipboard } from './clipboard';
import { initPhotoSwipe } from './photoswipe';
import { axiosErrorForHuman } from './axios';
import { registerGoogleMapCallback } from './maps';
import { isGoogleAnalyticsEnabled } from './ga';
import { isMatomoEnabled } from './matomo';
import { initSlickSliders } from './slick-slider';
import {
  bindGoogleRecaptchaCallback,
  executeGoogleRecaptcha,
  initGoogleRecaptchas,
  resetGoogleRecaptcha,
  removeGoogleRecaptcha,
} from './captcha';

import '../sentry';

global.serializeForm = serializeForm;
global.popitup = popitup;
global.axiosErrorForHuman = axiosErrorForHuman;
global.registerGoogleMapCallback = registerGoogleMapCallback;
global.isGoogleAnalyticsEnabled = isGoogleAnalyticsEnabled;
global.isMatomoEnabled = isMatomoEnabled;
global.initGoogleRecaptchas = initGoogleRecaptchas;
global.bindGoogleRecaptchaCallback = bindGoogleRecaptchaCallback;
global.executeGoogleRecaptcha = executeGoogleRecaptcha;
global.resetGoogleRecaptcha = resetGoogleRecaptcha;
global.removeGoogleRecaptcha = removeGoogleRecaptcha;

document.addEventListener(
  'DOMContentLoaded',
  () => {
    initPrintPages();
    initShareToNetworks();
    initShareToClipboard();
    initPhotoSwipe();
    initSlickSliders();
  },
  false
);
