const networks = {
  facebook: { width: 600, height: 300 },
  twitter: { width: 550, height: 420 },
  linkedin: { width: 600, height: 473 },
  // google: {width: 515, height: 490},
};

const shareToNetwork = ($el) => {
  const link = $el.href;
  const networkName = $el.dataset.shareSocialNetwork;
  const network = networks[networkName];

  const options = [
    'menubar=no',
    'toolbar=no',
    'resizable=yes',
    'scrollbars=yes',
    `height=${network.height}`,
    `width=${network.width}`,
  ];

  window.open(link, '', options.join(','));
};

export const initShareToNetworks = () => {
  const $shares = document.querySelectorAll('[data-share-social-network]');

  for (let i = 0, len = $shares.length; i < len; i += 1) {
    $shares[i].addEventListener('click', (e) => {
      shareToNetwork($shares[i]);
      e.preventDefault();
    });
  }
};
