import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://48a8870ba8994aa09d630959f4ba0062@sentry.io/128101',
    environment: global.sentryEnvironment || 'unconfigured-env',
  });

  Sentry.configureScope(function(scope) {
    scope.setTag('application', 'sites');
  });
}
