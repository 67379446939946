import { isPlymouth } from '../index';

export const canRegisterGoogleMapCallback = () => {
  if (!isPlymouth()) {
    throw new Error(`Can't use "canRegisterGoogleMapCallback()" when the site is not using Plymouth engine theme.`);
  }

  return Array.isArray(global.YproxGoogleMapsCallbacks);
};

export const registerGoogleMapCallback = (callback) => {
  if (!canRegisterGoogleMapCallback()) {
    throw new Error('Unable to initialize Google Maps, did you forget to create "site-google-maps" custom field?');
  }

  if (global.YproxGoogleMapsIsLoaded) {
    callback();
  } else {
    global.YproxGoogleMapsCallbacks.push(callback);
  }
};
