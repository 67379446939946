// @todo: debugger le code qui ne fonctionne pas pour le copy dans l'historique, ce code n'a pas l'air d'être appellé du tout
const copyToClipboard = (value) => {
  const $textarea = document.createElement('textarea');
  document.body.appendChild($textarea);
  $textarea.value = value;
  $textarea.select();

  document.execCommand('copy');

  document.body.removeChild($textarea);
  // @todo: Affichier un tooltip avec popper en dessus/dessous (mode automatique placement) de element pour dire que le lien a été copié dans le press papier
};

// @todo: Rajouter sur le hover de [data-copy-to-clipboard] un tooltip avec popper pour dire "cliquer pour copier le lien"
// bien sûr traduit
export const initShareToClipboard = () => {
  const $shareByCopy = document.querySelectorAll('[data-copy-to-clipboard]');

  for (let i = 0, len = $shareByCopy.length; i < len; i += 1) {
    $shareByCopy[i].addEventListener(
      'click',
      (e) => {
        copyToClipboard($shareByCopy[i].href);
        e.preventDefault();
      },
      false
    );
  }
};
