export const initGoogleRecaptchas = () => {
  global.recaptchaGoogle();
};

export const bindGoogleRecaptchaCallback = (contentId, callback) => {
  global.YproxRecaptchas[contentId].callback = callback;
};

export const executeGoogleRecaptcha = (contentId) => {
  global.grecaptcha.execute(global.YproxRecaptchas[contentId].captcha);
};

export const resetGoogleRecaptcha = (contentId) => {
  global.grecaptcha.reset(global.YproxRecaptchas[contentId].captcha);
};

export const removeGoogleRecaptcha = (contentId) => {
  delete global.YproxRecaptchas[contentId];
};
