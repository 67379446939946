/**
 * @typedef {Object} SlickSliderConfiguration
 * @property {Object} pluginConfig
 */

import $ from 'jquery';

/**
 * @return {SlickSliderConfiguration}
 */
const getConfig = ($el) => JSON.parse($el.dataset.configuration);

export class SlickSlider {
  constructor($slickSlider) {
    this.config = getConfig($slickSlider);
    this.$slickSlider = $slickSlider;

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('SlickSlider', {
        config: this.config,
        $slickSlider: this.$slickSlider,
      });
    }

    this.initPlugin();
  }

  initPlugin() {
    if (!this.config.miniature || !this.config.miniature.display) {
      $(this.$slickSlider).slick(this.config.pluginConfig);
      return;
    }

    $(this.$slickSlider).slick({
      ...this.config.pluginConfig,
      asNavFor: this.config.miniature.sliderNavReferenceClass,
      slidesToShow: 1,
    });
    $(this.config.miniature.sliderNavReferenceClass).slick({
      asNavFor: this.config.miniature.sliderForReferenceClass,
      slidesToShow: this.config.miniature.slidesToShow,
      slidesToScroll: 1,
      centerMode: true,
      focusOnSelect: true,
    });
  }
}
